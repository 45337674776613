.hero-section {
    height: 100vh;
    background-image: url('../../../public/assets/cover.webp'); /* Replace with the actual image path */
    background-size: cover;
    background-position: center;
    position: relative;
}

.overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Increased opacity for better contrast */
    z-index: 0;
}

.hero-content {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: white;
    z-index: 1;
    text-align: center; /* Center-align text for better responsiveness */
}

.hero-heading {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 1rem;
    color: #ffffff;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

.hero-subheading {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
    color: #ffffff;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

.hero-description {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    color: #ffffff;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}

.cta-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap; /* Ensure buttons wrap on smaller screens */
}

.cta-button {
    display: inline-block;
    background-color: #5D9CEC;
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 500;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #2C3E50;
}

/* Media Query for Smaller Screens (Tablets and below) */
@media (max-width: 768px) {
    .hero-heading {
        font-size: 2.25rem; /* Reduce font size */
    }

    .hero-subheading {
        font-size: 1.5rem; /* Reduce font size */
    }

    .hero-description {
        font-size: 1rem; /* Reduce font size */
    }

    .hero-content {
        padding: 15px; /* Reduce padding */
    }
}

/* Media Query for Very Small Screens (Mobile Devices) */
@media (max-width: 480px) {
    .hero-heading {
        font-size: 1.75rem; /* Further reduce font size */
    }

    .hero-subheading {
        font-size: 1.25rem; /* Further reduce font size */
    }

    .hero-description {
        font-size: 0.9rem; /* Further reduce font size */
    }

    .cta-button {
        padding: 0.5rem 1rem; /* Adjust button padding */
    }

    .hero-content {
        padding: 10px; /* Further reduce padding */
    }
}
