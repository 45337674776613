.about-section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
}

.about-section-heading {
  text-align: center;
  font-size: 2rem; /* Adjusted for smaller screens */
  margin-bottom: -5rem;
  margin-top: 3%;
  color: #2C3E50; /* Deep Blue */
  font-weight: 900;
}

.card-wrapper {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
  margin: 6% 10px 10px 10px;
}

.text-container {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
  padding: 20px;
  margin-top: 5%;
}

.section-heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #2C3E50; /* Deep Blue */
}

.section-content {
  font-size: 1rem;
  color: #333;
  margin-bottom: 16px;
  line-height: 1.5;
}

@media (max-width: 1024px) {
  .about-section-heading{
    font-size: 1.8rem;
  }

  .section-heading{
    font-size: 1.4rem;
  }

  .section-content{
    font-size: 0.9rem;
  }
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .about-section-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .about-section-heading {
    font-size: 1.75rem; /* Smaller font size for headings on small screens */
    margin-bottom: 1rem;
  }

  .card-wrapper,
  .text-container {
    min-width: 100%; /* Take full width */
    max-width: 100%;
    margin-top: 20px; /* Added margin for spacing */
  }

  .section-heading {
    font-size: 1.25rem; /* Slightly smaller font size for section headings */
  }

  .section-content {
    font-size: 0.9rem; /* Slightly smaller font size for text */
  }
}

/* Media Query for Very Small Screens (e.g., mobile devices) */
@media (max-width: 480px) {
  .about-section-heading {
    font-size: 1.4rem; /* Even smaller font size for very small screens */
  }

  .section-heading {
    font-size: 1.1rem;
  }

  .section-content {
    font-size: 0.7rem;
  }

  .text-container {
    padding: 10px;
  }
}
