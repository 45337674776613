/* ContactPage.css */

/* Background Image and Section */
.contact-section {
    min-height: 100vh;
    background-image: url('../assets/contactcover.png');
    background-size: cover;
    background-position: center;
  }
  
  /* Container styling */
  .contact-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.6); /* Transparent black background */
    padding: 6rem 3rem;
  }
  
  /* Content alignment for large screens */
  .contact-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    /* Flexbox layout for larger screens (row direction) */
  }
  
  /* Left Section: Home Visits Info */
  .left-section {
    color: white;
    flex: 1;
    margin-right: 2rem;
  }
  
  .left-section h1 {
    font-size: 2rem;
    font-weight: 600;
  }
  
  .left-section p {
    margin-top: 1.5rem;
  }
  
  .left-section .home-visits-info {
    margin-top: 2rem;
    color: #e5e5e5;
  }
  
  .left-section .home-visits-info p {
    margin-top: 1rem;
  }
  
  .left-section .social-media {
    margin-top: 2rem;
  }
  
  .left-section .social-media h3 {
    color: #b3b3b3;
  }
  
  .left-section .social-media .icons {
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
  }
  
  /* Right Section: Contact Form */
  .right-section {
    margin-top: 2rem;
    flex: 1;
  }
  
  .right-section .contact-form-container {
    padding: 2rem;
    background-color: #2C3E50;
    border-radius: 0.75rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .right-section h1 {
    font-size: 1.25rem;
    font-weight: 500;
  }
  
  .right-section p {
    margin-top: 1rem;
    color: #5a5a5a;
  }
  
  .right-section input,
  .right-section textarea {
    width: 100%;
    padding: 0.75rem;
    margin-top: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 0.375rem;
    font-size: 1rem;
  }
  
  .right-section button {
    width: 100%;
    padding: 0.75rem;
    margin-top: 1.5rem;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
  }
  
  .right-section button:hover {
    background-color: #0056b3;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .contact-content {
      flex-direction: column; /* Stack sections vertically */
      align-items: flex-start; /* Align items to the start */
    }
  
    .left-section,
    .right-section {
      margin-right: 0; /* Remove right margin on mobile */
      width: -webkit-fill-available;
    }
  
    .right-section .contact-form-container {
      padding: 1.5rem; /* Reduce padding for mobile */
    }
  
    /* Adjusting text size for smaller screens */
    .left-section h1 {
      font-size: 1.5rem;
    }
  
    .left-section p {
      font-size: 1rem;
    }
  
    .right-section h1 {
      font-size: 1.125rem;
    }
  
    .right-section p {
      font-size: 0.875rem;
    }
  }
  