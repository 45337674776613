body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    background-color: #F4F6F7; /* Light Grey */
}

.nav-bar {
    background-color: #2C3E50; /* Accent Color: Deep Blue */
    color: #FFFFFF; /* White text */
    padding: 15px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
}

.nav-logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #A3E4D7; /* Secondary Color: Mint Green */
}

.hamburger-btn {
    display: none; /* Hide by default */
    background-color: transparent;
    border: none;
    color: #FFFFFF;
    font-size: 1.5rem;
    cursor: pointer;
}

.nav-links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex; /* Default to flex for larger screens */
}

.nav-links li {
    margin: 0 5px;
}

.nav-item {
    text-decoration: none;
    color: #FFFFFF; /* White text */
    font-size: 1rem;
    transition: color 0.3s, background-color 0.3s;
    padding: 10px 15px; /* Add padding for better clickable area */
    border-radius: 30px; /* Rounded corners for better UX */
}

.nav-item:hover {
    color: #2C3E50; /* Deep Blue text color on hover */
    background-color: #A3E4D7; /* Mint Green background for better contrast */
}

/* Active Link Styling */
.nav-item.active-link {
    color: #2C3E50; /* Deep Blue text color */
    background-color: #A3E4D7; /* Mint Green background */
    font-weight: bold; /* Make the active link bold for emphasis */
}

/* Responsive Styles for Small Screens */
@media (max-width: 768px) {
.hero-section{
    height: 95vh;
}
    
    .nav-logo{
        font-size: 1.2rem;
    }

    .hamburger-btn {
        display: inline-flex; /* Show hamburger button on small screens */
    }

    .nav-links {
        display: none; /* Hide the navigation links initially on mobile */
        flex-direction: column;
        background-color: #2C3E50; /* Match navbar background color */
        position: absolute;
        top: 60px; /* Position below the navbar, accounting for navbar height */
        left: 0;
        width: 100%;
        padding: 10px 0;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for better visual separation */
    }

    .nav-links.show {
        display: flex; /* Show the navigation links when hamburger button is clicked */
        align-items: center;
    }

    .nav-item {
        width: 50%;
        text-align: center;
        padding: 15px;
        border-bottom: 1px solid #A3E4D7; /* Divider between links */
    }

    .nav-item:last-child {
        border-bottom: none; /* Remove bottom border for last item */
    }

    .nav-links li{
        margin: 1.3rem 0 0 0;
    display: flex
;
    width: 70%;
    justify-content: center;
    align-items: center;
    }
}

@media (max-width: 480px) {
    .hero-heading{
        font-size: 1.75rem;
    }

    .hero-subheading{
        font-size: 1.2rem;
    }

    .hero-content{
        padding: 0 20px;
    }
}

@media (max-width: 320px) {
    .hero-heading{
        font-size: 1.5rem;
    }

    .hero-subheading{
        font-size: 1rem;
    }

    .hero-description{
        font-size: 0.7rem;
    }
}