/* Root Container */
.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

/* Card Container */
.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 300px;
  height: 430px;
  perspective: 1000px;
}

/* Background Splash */
.splash-container {
  position: absolute;
  top: 7rem;
  /* left: 0; */
  width: 28rem;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.card-base-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  /* filter: blur(5px); */
}

/* Card */
.card {
  position: relative;
  width: 260px;
  height: 400px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transform-origin: center;
}

.card-photo {
  width: 90%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

/* Responsive Design */
@media (max-width: 768px) {
  .card-container {
    width: 250px;
    height: 350px;
  }

  .card {
    width: 230px;
    height: 320px;
    left: -15px;
  }

  .splash-container{
    aspect-ratio: 1 / 1;
    width: 23rem;
    left: -60px;
  }
}

@media (max-width: 425px) {
  .card-container {
    width: 200px;
    height: 300px;
  }

  .card {
    width: 160px;
    height: 240px;
  }

  .splash-container{
    width: 145%;
    height: 93%;
  }

  .cards-wrapper{
    padding: 0;
  }
}

