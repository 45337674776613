/* Services Page Custom Styling */

/* Accordion hover effect */
.accordion-item {
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.accordion-item:hover {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
}

/* Icon rotation when accordion is active */
.accordion-item .active-icon {
  transition: transform 0.3s ease-in-out;
}
.accordion-item .active-icon.rotated {
  transform: rotate(180deg);
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

/* Carousel styling */
.carousel-container {
  overflow-x: hidden;
  padding: 2rem 0;
  position: relative;
}
.carousel-container .carousel-item {
  min-width: 250px;
  margin-right: 1rem;
  transition: transform 0.3s ease-in-out;
}
.carousel-container .carousel-item:hover {
  transform: scale(1.05);
}

/* Custom scrollbar for carousel */
.carousel-container::-webkit-scrollbar {
  height: 8px;
}
.carousel-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}
.carousel-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

/* Button animations */
button.custom-button {
  background-color: #3b82f6;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}
button.custom-button:hover {
  background-color: #2563eb;
}
button.custom-button:active {
  transform: scale(0.95);
}

/* General hover effect for cards */
.card-hover {
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}
.card-hover:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
}

/* Marquee styling */
.my-marquee {
  display: flex;
  background-color: cadetblue;
  color: #0B0B0B;
  height: 8rem;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.marquee {
  overflow: hidden;
  position: relative;
  width: 100%; /* Ensure the container is full width */
}

.marquee-content {
  display: block;
  position: absolute;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
  font-weight: bold;
  width: auto; /* Ensure the content width is automatically adjusted */
}

/* Responsive Design */

/* For small tablets and mobile devices */
@media (max-width: 768px) {

  /* Accordion items */
  .accordion-item {
      padding: 1rem;
  }

  /* Carousel Items */
  .carousel-container {
      padding: 1rem 0;
  }
  .carousel-container .carousel-item {
      min-width: 200px; /* Smaller carousel item width */
      margin-right: 0.5rem;
  }

  /* Marquee adjustments */
  .my-marquee {
      height: 6rem; /* Reduce height on small screens */
  }

  .marquee-content {
      font-size: 1.2rem; /* Slightly smaller font size */
  }

  /* Button */
  button.custom-button {
      padding: 0.5rem 1rem; /* Adjust button padding */
      font-size: 0.875rem;
  }

  /* Card hover effect */
  .card-hover {
      margin-bottom: 1rem; /* Add some spacing between cards */
  }

  /* Mobile View for Form */
  .right-section .contact-form-container {
      padding: 1.5rem; /* Reduced padding on mobile */
  }

  /* Carousel adjustments */
  .carousel-container::-webkit-scrollbar {
      height: 6px;
  }

  .services-page{
    padding: 0 2rem;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  
  /* Accordion item text */
  .accordion-item {
      font-size: 0.875rem; /* Smaller font size for small screens */
  }

  /* Adjustments for marquee */
  .my-marquee {
      height: 5rem; /* Further reduce height for very small screens */
  }

  .marquee-content {
      font-size: 1rem; /* Smaller font size */
  }

  /* Button */
  button.custom-button {
      padding: 0.5rem 0.75rem; /* Further reduce padding */
      font-size: 0.875rem;
  }

  /* Adjust carousel for very small screens */
  .carousel-container .carousel-item {
      min-width: 150px; /* Make carousel items even smaller */
  }
}


/* Base Styles for Text */
.text-xl {
  font-size: 2.25rem; /* Default font size for extra-large text */
}

.text-lg {
  font-size: 1.125rem; /* Default font size for large text */
}

.text-base {
  font-size: 1rem; /* Default font size for base text */
}

.text-sm {
  font-size: 0.875rem; /* Default font size for small text */
}

.text-gray-800 {
  color: #2d3748; /* Dark gray for headings */
}

.text-gray-700 {
  color: #4a5568; /* Light gray for descriptions */
}

.text-gray-600 {
  color: #718096; /* Light gray for descriptions */
}

/* Responsive Font Sizes */
@media (max-width: 768px) {
  .text-xl {
    font-size: 2rem; /* Smaller font size for extra-large text */
  }

  .text-lg {
    font-size: 0.9375rem; /* Smaller font size for large text */
  }

  .text-base {
    font-size: 0.875rem; /* Smaller font size for base text */
  }

  .text-sm {
    font-size: 0.8125rem; /* Smaller font size for small text */
  }
}

@media (max-width: 480px) {
  .text-xl {
    font-size: 1.875rem; /* Extra-small font size for very small screens */
  }

  .text-lg {
    font-size: 0.9125rem; /* Extra-small font size for large text */
  }

  .text-base {
    font-size: 0.75rem; /* Extra-small font size for base text */
  }

  .text-sm {
    font-size: 0.6875rem; /* Extra-small font size for small text */
  }
}

.text-blue-500 {
  color: #3b82f6; /* Blue color for the arrow */
}

.text-blue-500:hover {
  color: #2563eb; /* Darker blue on hover */
}